import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy, PropertyMap} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Incidencia} from "./incidencia.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {LineaPedido} from "../pedidos/linea-pedido.model";
import {BaseEntity} from "../base-entity.model";

@BasicEntity({
    name: 'Incidencia de Linea de Pedido',
    endpoint: 'incidencia_linea_pedidos',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'IncidenciaLineaPedido'
})
export class IncidenciaLineaPedido extends BaseEntity {

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Linea de Pedido',
        type: PropertyType.NestedModel(LineaPedido, null, NestedBehavior.SendIriOnly)
    })
    private _lineaPedido: LineaPedido

    get lineaPedido(): LineaPedido {
        return this._lineaPedido;
    }

    set lineaPedido(value: LineaPedido) {
        this._lineaPedido = value;
    }

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }
}
