import {BasicEntity, BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ManagerCachingStrategy} from "../../basic-entity-back/basic-entity-interface/mapping-external";
import {Incidencia} from "./incidencia.model";
import {Pedido} from "../pedidos/pedido.model";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {IncidenciaLineaPedido} from "./incidencia-linea-pedido.model";

@BasicEntity({
    name: 'Incidencias de Pedido',
    endpoint: 'incidencia_pedidos',
    isPaginated: true,
    autogeneratedId: false,
    modelNameProperty: 'descripcion',
    acceptsBulkIriSearch: true,
    managerCaching: ManagerCachingStrategy.Lazy,
    type: 'IncidenciaPedido'
})
export class IncidenciaPedido extends Incidencia {
    @BasicProperty({
        name: 'Pedido',
        type: PropertyType.NestedModel(Pedido, null, NestedBehavior.SendIriOnly)
    })
    private _pedido: Pedido

    @BasicProperty({
        name: 'Lineas de incidencia',
        type: PropertyType.NestedModel(IncidenciaLineaPedido, null, NestedBehavior.CreateOnly),
        array: true
    })
    private _lineasIncidencia: IncidenciaLineaPedido[] = []


    get pedido(): Pedido {
        return this._pedido;
    }

    set pedido(value: Pedido) {
        this._pedido = value;
    }

    get lineasIncidencia(): IncidenciaLineaPedido[] {
        return this._lineasIncidencia;
    }

    set lineasIncidencia(value: IncidenciaLineaPedido[]) {
        this._lineasIncidencia = value;
    }
}
