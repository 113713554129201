import {BasicProperty} from "../../basic-entity-back/annotations";
import {PropertyType} from "../../basic-entity-back/property-type/property-type";
import {ExactSearchFilter} from "../../basic-entity-back/filters/search-filter";
import {NestedBehavior} from "../../basic-entity-back/property-type/nested-model-type";
import {Empleado} from "../personas/empleado.model";
import {UserGroup} from "../personas/user-group.model";
import {Usuario} from "../personas/usuario.model";
import {BaseEntity} from "../base-entity.model";
import {Cliente} from "../personas/cliente.model";
import {ReadWrite} from "../../basic-entity-back/basic-entity-interface/mapping-external";

export abstract class Incidencia extends BaseEntity {
    static readonly ESTADOS_INCIDENCIA = {
        PENDIENTE: 'Pendiente',
        EN_CURSO: "En curso",
        RESUELTA: "Resuelta",
        DESCARTADA: "Descartada"
    };

    static readonly PRIORIDAD_INCIDENCIA = {
        URGENTE: 'Urgente',
        ALTA: "Alta",
        NORMAL: "Normal",
        BAJA: "Baja"
    };

    @BasicProperty({
        name: 'ID',
        isId: true,
        type: PropertyType.Integer()
    })
    private _id;

    @BasicProperty({
        name: 'Descripción de la incidencia',
        type: PropertyType.String(),
        nullable: true
    })
    private _descripcion;

    @BasicProperty({
        name: 'Título de la incidencia',
        type: PropertyType.String(),
        nullable: true,
        readWrite: ReadWrite.ReadOnly
    })
    private _titulo;

    @BasicProperty({
        name: 'Estado',
        type: PropertyType.Choice(...Object.values(Incidencia.ESTADOS_INCIDENCIA)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _estado;

    @BasicProperty({
        name: 'Prioridad',
        type: PropertyType.Choice(...Object.values(Incidencia.PRIORIDAD_INCIDENCIA)),
        sortable: true,
        filters: [ExactSearchFilter]
    })
    private _prioridad;

    @BasicProperty({
        name: 'Asignada a',
        type: PropertyType.NestedModel(Empleado, null, NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter]
    })
    private _asignadaA;

    @BasicProperty({
        name: 'Asignada a',
        type: PropertyType.NestedModel(Usuario, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _asignadaAUsuario;

    @BasicProperty({
        name: 'Departamento afectado',
        type: PropertyType.NestedModel(UserGroup, null, NestedBehavior.SendIriOnly),
        nullable: true,
        filters: [ExactSearchFilter]
    })
    private _departamentoAfectado;

    @BasicProperty({
        name: 'Cliente afectado',
        type: PropertyType.NestedModel(Cliente, null, NestedBehavior.SendIriOnly),
        nullable: true
    })
    private _clienteAfectado;

    @BasicProperty({
        name: 'Es pública',
        type: PropertyType.Boolean()
    })
    private _publica = false;

    @BasicProperty({
        name: 'Email aviso',
        type: PropertyType.String()
    })
    private _emailAviso = '';

    get id() {
        return this._id;
    }

    set id(value) {
        this._id = value;
    }

    get descripcion() {
        return this._descripcion;
    }

    set descripcion(value) {
        this._descripcion = value;
    }

    get estado() {
        return this._estado;
    }

    set estado(value) {
        this._estado = value;
    }

    get prioridad() {
        return this._prioridad;
    }

    set prioridad(value) {
        this._prioridad = value;
    }

    get asignadaA() {
        return this._asignadaA;
    }

    set asignadaA(value) {
        this._asignadaA = value;
    }

    get departamentoAfectado() {
        return this._departamentoAfectado;
    }

    set departamentoAfectado(value) {
        this._departamentoAfectado = value;
    }

    get asignadaAUsuario() {
        return this._asignadaAUsuario;
    }

    set asignadaAUsuario(value) {
        this._asignadaAUsuario = value;
    }

    get clienteAfectado() {
        return this._clienteAfectado;
    }

    set clienteAfectado(value) {
        this._clienteAfectado = value;
    }

    get publica(): boolean {
        return this._publica;
    }

    set publica(value: boolean) {
        this._publica = value;
    }

    get titulo() {
        return this._titulo;
    }

    set titulo(value) {
        this._titulo = value;
    }

    get emailAviso(): string {
        return this._emailAviso;
    }

    set emailAviso(value: string) {
        this._emailAviso = value;
    }
}
